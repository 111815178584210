import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import Client from "../../shared/client";
import { UtHomeContext } from '../../store/contexts/UtHomeContext';
import { UT_EDIT_TOP_FOUR_SOLUTIONS, UT_EDIT_TOP_SOLUTIONS } from '../../store/reducers/UtHomeReducer';

export const UtEditTopServices = () => {
    const [utHomeData, dispatchUtHomeData] = useContext(UtHomeContext);
    const [optionSelected, setOptionSelected] = useState([]);
    const [options, setOptions] = useState([]);

    const FOUR_OPTION_MAX_SELECT = 4;

    const fetchSolutions = async () => {
        const { data } = await Client('business-type', 'GET', undefined, true);
        return data;
    }

    useEffect(() => {
        const _fetchSolutions = async () => {
            const _solutions = await fetchSolutions();
            const _options = _solutions.map(({ id, title }) => {
                return { value: id, label: title };
            });
            setOptions(_options);
        }
        _fetchSolutions();
    }, [])

    useEffect(() => {
        const _optionSelected = utHomeData.topSolutions.map(({ value, label }) => {
            return { value, label };
        })
        setOptionSelected((_optionSelected));
    }, [options, utHomeData])

    const onOptionsChange = (selectedOptions) => {
        selectedOptions = selectedOptions || []; // selectedOptions === null nếu ko có phần tử được selected
        const results = selectedOptions.map(({ value }) => {
            return _.find(options, { value });
        })
        dispatchUtHomeData({
            type: UT_EDIT_TOP_SOLUTIONS,
            payload: results
        })
    }

    const onFourOptionsChange = (selectedOptions) => {
        selectedOptions = selectedOptions || []; // selectedOptions === null nếu ko có phần tử được selected
        const results = selectedOptions.map(({ value }) => {
            return _.find(options, { value });
        })
        dispatchUtHomeData({
            type: UT_EDIT_TOP_FOUR_SOLUTIONS,
            payload: results
        })
    }

    return (
        <>
            <h5>Chọn các services để hiện thị cho page Service!</h5>
            <Select options={options} isMulti={true} onChange={onOptionsChange}
                value={optionSelected} />
            <hr/>
            <h5>Chọn các services để hiện thị cho page Home (Max 4 options)!</h5>
            <Select options={options} isMulti={true} onChange={onFourOptionsChange}
                isOptionDisabled={() => utHomeData.fourSolutions.length >= FOUR_OPTION_MAX_SELECT}
                value={utHomeData.fourSolutions} />
        </>
    )
}

import React, { useContext, useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import Select from 'react-select';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    FormGroup,
    FormInput,
    Row
} from 'shards-react';
import Client from '../../shared/client';
import { AuthContext } from '../../store/contexts/AuthContext';
import { UtProjectState } from '../../store/states/UtProjectState';
import Editor from '../@ut-editor/@editor';
import CustomFileUpload from '../components-overview/CustomFileUpload';

export const UtProjectComponent = ({ project }) => {
    const [user] = useContext(AuthContext);
    const [utProjectData, setUtProjectData] = useState(UtProjectState);
    const [editor, setEditor] = useState(null);
    const [isBtnCreateDisabled, setBtnCreateDisabled] = useState(true);
    const [businessTypeOptions, setBusinessTypeOptions] = useState([]);
    const [selectedBusinessType, setSelectedBusinessType] = useState(null);

    useEffect(() => {
        if (utProjectData.id) {
            setSelectedBusinessType({
                value: utProjectData.businessType.id,
                label: utProjectData.businessType.title,
            });
        }
    }, [utProjectData]);

    // fetch business type options
    useEffect(() => {
        const _fetchBusinessTypes = () => {
            Client('business-type', 'GET', undefined, true).then(({ data }) => {
                if (data.length === 0) return;
                const _options = data.map(({ id, title }) => ({
                    value: id,
                    label: title,
                }));
                setBusinessTypeOptions(_options);
            });
        };
        _fetchBusinessTypes();
    }, []);

    useEffect(() => {
        setUtProjectData(project);
    }, [project]);

    useEffect(() => {}, [utProjectData]);

    const onChange = ({ target }) => {
        const { name, value } = target;
        setUtProjectData({
            ...utProjectData,
            [name]: value,
        });
        if (
            editor === null ||
            utProjectData.title.trim() === '' ||
            editor.getData().trim() === ''
        ) {
            setBtnCreateDisabled(true);
        } else setBtnCreateDisabled(false);
    };

    const onSubmit = () => {
        const _projectData = { ...utProjectData };

        delete _projectData.businessType;
        delete _projectData.creator;
        delete _projectData.createdAt;
        delete _projectData.updatedAt;

        _projectData.businessTypeId = selectedBusinessType.value;
        _projectData.content = editor.getData();
        if (!utProjectData.id || utProjectData.id === '') {
            delete _projectData.id;
            Client('business', 'POST', _projectData, true)
                .then(({ data }) => {
                    store.addNotification({
                        title: 'Tạo thành công!',
                        message: `Dự án: ${data.title} đã được tạo thành công.`,
                        type: 'success',
                        insert: 'bottom',
                        container: 'bottom-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 2000,
                            // onScreen: true
                        },
                    });
                })
                .catch(e => {
                    store.addNotification({
                        title: 'Error!',
                        message: `Tạo dự án thất bại: ${utProjectData.title}.`,
                        type: 'danger',
                        insert: 'bottom',
                        container: 'bottom-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 2000,
                            // onScreen: true
                        },
                    });
                });
        } else {
            Client(
                `business/${utProjectData.id}`,
                'PATCH',
                { ..._projectData, businessTypeId: selectedBusinessType.value },
                true,
            )
                .then(({ data }) => {
                    store.addNotification({
                        title: 'Cập nhật thành công',
                        message: `Dự án: ${data.title} đã được cập nhật thành công. Bạn sẽ được chuyển hướng về trang UT projects trong 3 giây.`,
                        type: 'success',
                        insert: 'bottom',
                        container: 'bottom-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 3000,
                            onScreen: true,
                        },
                    });
                    setTimeout(() => {
                        window.location.assign('/ut-projects');
                    }, 3000);
                })
                .catch(e => {
                    store.addNotification({
                        title: 'Error!',
                        message: `Cập nhật dự án thất bại: ${utProjectData.title}. Message: ${e}`,
                        type: 'danger',
                        insert: 'bottom',
                        container: 'bottom-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 2000,
                            // onScreen: true
                        },
                    });
                });
        }
    };

    const isAllowed = () => {
        return true;
    };

    const onDel = () => {
        if (window.confirm('Bạn chắc chắn muốn xoá nó chứ?')) {
            Client(`business/${utProjectData.id}`, 'DELETE', true)
                .then(data => {
                    store.addNotification({
                        title: 'Xoá thành công',
                        message: `Dự án: ${utProjectData.title} đã được xoá thành công. Bạn sẽ được chuyển hướng về trang UT services trong 3 giây.`,
                        type: 'success',
                        insert: 'bottom',
                        container: 'bottom-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 3000,
                            onScreen: true,
                        },
                    });
                    setTimeout(() => {
                        window.location.assign('/ut-projects');
                    }, 3000);
                })
                .catch(e => {
                    store.addNotification({
                        title: 'Lỗi!',
                        message: `Xoá dự án thất bại: ${utProjectData.title}. Message: ${e}`,
                        type: 'danger',
                        insert: 'bottom',
                        container: 'bottom-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 2000,
                            // onScreen: true
                        },
                    });
                });
        }
    };

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col md={12} sm={12}>
                        <Row>
                            <Col md={8} sm={12}>
                                <FormGroup>
                                    <label htmlFor="p-title">Tiêu đề</label>
                                    <FormInput
                                        placeholder="Project title"
                                        name="title"
                                        className="mb-2"
                                        id="p-title"
                                        value={utProjectData.title}
                                        onChange={e => {
                                            onChange(e);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4} sm={12} style={{ marginTop: 'auto', zIndex: 2 }}>
                                <FormGroup>
                                    <label htmlFor="p-date">
                                        Dịch vụ liên quan
                                    </label>
                                    <br />
                                    {/* <DatePicker
                                        selected={moment(utProjectData.startDate).toDate()}
                                        id="p-date"
                                        onChange={(value) => onChange({
                                            target: {
                                                name: 'startDate',
                                                value: moment(value).format('YYYY-MM-DD')
                                            }
                                        })} /> */}
                                    <Select
                                        options={businessTypeOptions}
                                        value={selectedBusinessType}
                                        onChange={setSelectedBusinessType}
                                        isMulti={false}
                                    ></Select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <label htmlFor="p-image">Image</label>
                            <CustomFileUpload
                                id="p-image"
                                marginText="mb-2"
                                link={utProjectData.image.fileUrl}
                                fileName={utProjectData.image.originalName}
                                endpoint="ut-uploader"
                                onFileChange={(fileUrl, originalName) => {
                                    const target = {
                                        name: 'image',
                                        value: {
                                            fileUrl,
                                            originalName,
                                        },
                                    };
                                    onChange({ target });
                                }}
                            />
                        </FormGroup>
                        <Editor
                            value={utProjectData.content}
                            onChange={editor => {
                                if (
                                    utProjectData.title.trim() === '' ||
                                    editor.getData().trim() === ''
                                ) {
                                    setBtnCreateDisabled(true);
                                } else setBtnCreateDisabled(false);
                                setEditor(editor);
                            }}
                        />
                    </Col>
                </Row>
            </CardBody>
            <CardFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {utProjectData.id ? (
                    <Button
                        theme={'danger'}
                        disabled={!isAllowed()}
                        onClick={() => onDel()}
                    >
                        Xoá
                    </Button>
                ) : (
                    <></>
                )}
                <Button
                    theme={'success'}
                    disabled={!isAllowed() || isBtnCreateDisabled}
                    className="ml-5"
                    onClick={() => onSubmit()}
                >
                    {utProjectData.id ? 'Update' : 'Create'}
                </Button>
            </CardFooter>
        </Card>
    );
};

export default UtProjectComponent;

/*
 * Created by @tranphuquy19 on 05/12/2020
 * @author: tranphuquy19@gmail.com
 */
const config = {
    // API_URL : `https://rizo-light.tk`,
    // API_URL: process.env.API_URL || `http://localhost:4000`,
    // API_URL: process.env.API_URL || `http://rizo-light.kom`,
    // UT_API_URL: process.env.UT_API_URL || `http://utec.vm`,
    // API_URL: process.env.API_URL || `https://dev.rizo-light.com`,
    // UT_API_URL: process.env.UT_API_URL || `https://utec.rizo-light.com`,

    API_URL: process.env.API_URL || `https://rizo-light.com`,
    UT_API_URL: process.env.UT_API_URL || `https://utec.vn`,
};

export default config;

import React, { useContext, useState } from 'react';
import {
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    FormGroup,
    FormInput,
    Collapse,
    FormCheckbox,
} from 'shards-react';
import { UtHomeContext } from '../store/contexts/UtHomeContext';
import {
    UT_EDIT_OFFICES_INFO,
    UT_EDIT_SOCIAL_LINKS,
    UT_TOGGLE_CONTACT_US,
} from '../store/reducers/UtHomeReducer';

export const UtTagPageInfo = () => {
    const [utHomeData, dispatchUtHomeData] = useContext(UtHomeContext);
    const [open, setOpen] = useState(false);

    function onChangeInput(e) {
        const { value, name } = e.target;
        if (
            name === 'ggAddressLink' ||
            name === 'fbLink' ||
            name === 'ytLink' ||
            name === 'twitterLink' ||
            name === 'vietnameseTags'
        )
            dispatchUtHomeData({
                type: UT_EDIT_SOCIAL_LINKS,
                payload: { name, value },
            });
        else
            dispatchUtHomeData({
                type: UT_EDIT_OFFICES_INFO,
                payload: {
                    indexOffice: 1,
                    nameOffice: name,
                    valueOffice: value,
                },
            });
    }

    return (
        <Card className="mb-4">
            <CardHeader className="border-bottom">
                <h6
                    className="m-0"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setOpen(!open)}
                >
                    Page Info. (Footer, Google Maps, Social Links, Tags,
                    Keywords, SEO,...){' '}
                    <i className="material-icons">
                        {open ? 'expand_less' : 'expand_more'}
                    </i>
                </h6>
            </CardHeader>
            <ListGroup flush>
                <Collapse open={open}>
                    <ListGroupItem className="p-3">
                        <Row>
                            <Col>
                                <Form>
                                    {/*Home content*/}
                                    <Row form>
                                        {/* Home title */}
                                        <Col md="6" className="form-group">
                                            <label htmlFor="feHomeTitle">
                                                Home Title
                                            </label>
                                            <FormInput
                                                name="homeTitle"
                                                id="feHomeTitle"
                                                type="tel"
                                                placeholder="Home Title Name"
                                                value={utHomeData.homeTitle}
                                                onChange={onChangeInput}
                                                required
                                            />
                                        </Col>
                                        {/* Home description */}
                                        <Col md="6" className="form-group">
                                            <label htmlFor="feHomeDescription">
                                                Home Description
                                            </label>
                                            <FormInput
                                                name="homeDescription"
                                                id="feHomeDescription"
                                                placeholder="Home Title Descrip Tion"
                                                value={
                                                    utHomeData.homeDescription
                                                }
                                                onChange={onChangeInput}
                                            />
                                        </Col>
                                    </Row>
                                    <Row form>
                                        {/* Organization's Name */}
                                        <Col md="6" className="form-group">
                                            <label htmlFor="feOrganizationName">
                                                Organization Name
                                            </label>
                                            <FormInput
                                                name="organizationName"
                                                id="feOrganizationName"
                                                placeholder="Organization's Name"
                                                value={
                                                    utHomeData.offices[0]
                                                        .organizationName
                                                }
                                                onChange={onChangeInput}
                                                autoComplete="current-password"
                                            />
                                        </Col>
                                        {/* Email */}
                                        <Col md="6" className="form-group">
                                            <label htmlFor="feEmail">
                                                Email
                                            </label>
                                            <FormInput
                                                name="email"
                                                type="email"
                                                id="feEmail"
                                                placeholder="Email Address"
                                                value={
                                                    utHomeData.offices[0].email
                                                }
                                                onChange={onChangeInput}
                                            />
                                        </Col>
                                    </Row>
                                    <Row form>
                                        {/* Phone Number */}
                                        <Col md="6" className="form-group">
                                            <label htmlFor="fePhoneNumber">
                                                Phone Number
                                            </label>
                                            <FormInput
                                                name="phoneNumber"
                                                id="fePhoneNumber"
                                                type="tel"
                                                placeholder="+ 08 888 888"
                                                value={
                                                    utHomeData.offices[0]
                                                        .phoneNumber
                                                }
                                                onChange={onChangeInput}
                                                required
                                            />
                                        </Col>
                                        {/* Fax */}
                                        <Col md="6" className="form-group">
                                            <label htmlFor="feFax">Fax</label>
                                            <FormInput
                                                name="fax"
                                                id="feFax"
                                                placeholder="Fax Number"
                                                value={
                                                    utHomeData.offices[0].fax
                                                }
                                                onChange={onChangeInput}
                                            />
                                        </Col>
                                    </Row>
                                    {/*  Address*/}
                                    <FormGroup>
                                        <label htmlFor="feAddress">
                                            Address
                                        </label>
                                        <FormInput
                                            name="address"
                                            id="feAddress"
                                            placeholder="Address"
                                            value={
                                                utHomeData.offices[0].address
                                            }
                                            onChange={onChangeInput}
                                        />
                                    </FormGroup>
                                    <Row form>
                                        <Col md="6" className="form-group">
                                            <label htmlFor="feOpenTime">
                                                Open Time
                                            </label>
                                            <FormInput
                                                name="openTime"
                                                id="feOpenTime"
                                                placeholder="Mon - Sat: 10 am - 6 pm | Sun: 12pm - 2 pm"
                                                value={
                                                    utHomeData.offices[0]
                                                        .openTime
                                                }
                                                onChange={onChangeInput}
                                            />
                                        </Col>
                                        <Col md="6" className="form-group">
                                            <label htmlFor="feTags">Tags</label>
                                            <FormInput
                                                name="tags"
                                                id="feTags"
                                                placeholder="Tags"
                                                value={
                                                    utHomeData.tags[0].content
                                                }
                                                onChange={onChangeInput}
                                            />
                                        </Col>
                                        <Col md="12" className="form-group">
                                            <label htmlFor="feOpenTimeShortDescription">
                                                Open Time (Mô tả ngắn footer)
                                            </label>
                                            <FormInput
                                                name="openTimeShortDescription"
                                                id="feOpenTimeShortDescription"
                                                placeholder="We work 7 days a week, every day excluding major holidays. Contact us if you have an emergency, with our Hotline and Contact form."
                                                value={
                                                    utHomeData.offices[0]
                                                        .openTimeShortDescription
                                                }
                                                onChange={onChangeInput}
                                            />
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col md="6" className="form-group">
                                            <label htmlFor="feOpenTime">
                                                Google maps link
                                            </label>
                                            <FormInput
                                                name="ggAddressLink"
                                                id="feOpenTime"
                                                placeholder="https://goo.gl"
                                                value={utHomeData.ggAddressLink}
                                                onChange={onChangeInput}
                                            />
                                        </Col>
                                        <Col md="6" className="form-group">
                                            <label htmlFor="feOpenTime">
                                                Fan page link
                                            </label>
                                            <FormInput
                                                name="fbLink"
                                                id="feOpenTime"
                                                placeholder="https://www.facebook.com/"
                                                value={utHomeData.fbLink}
                                                onChange={onChangeInput}
                                            />
                                        </Col>
                                        <Col md="6" className="form-group">
                                            <label htmlFor="feOpenTime">
                                                Youtube channel link
                                            </label>
                                            <FormInput
                                                name="ytLink"
                                                id="feOpenTime"
                                                placeholder="https://youtube.com/channel"
                                                value={utHomeData.ytLink}
                                                onChange={onChangeInput}
                                            />
                                        </Col>
                                        <Col md="6" className="form-group">
                                            <label htmlFor="feOpenTime">
                                                Twitter page link
                                            </label>
                                            <FormInput
                                                name="twitterLink"
                                                id="feOpenTime"
                                                placeholder="https://twitter.com"
                                                value={utHomeData.twitterLink}
                                                onChange={onChangeInput}
                                            />
                                        </Col>
                                        <Col md={12} className="form-group">
                                            <label htmlFor="feOpenTime">
                                                Vietnamese tags (keywords)
                                            </label>
                                            <FormInput
                                                name="vietnameseTags"
                                                id="feOpenTime"
                                                placeholder="Rizo-lighting"
                                                value={
                                                    utHomeData.vietnameseTags
                                                }
                                                onChange={onChangeInput}
                                            />
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <span style={{ display: 'flex', alignItems: 'center', marginLeft: '4px' }}>
                                            <FormCheckbox
                                                toggle
                                                checked={
                                                    utHomeData.isShowContactUs
                                                }
                                                onChange={() =>
                                                    dispatchUtHomeData({
                                                        type: UT_TOGGLE_CONTACT_US,
                                                    })
                                                }
                                            ></FormCheckbox>
                                            <span>Show call us</span>
                                        </span>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </ListGroupItem>
                </Collapse>
            </ListGroup>
        </Card>
    );
};

import React, { useEffect, useState } from 'react';
import {
    Button, Card,
    CardBody, CardFooter,
    Col,
    Container,
    FormGroup,
    FormInput,
    Row
} from "shards-react";
import PageTitle from '../components/common/PageTitle';

import { store } from "react-notifications-component";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import CustomFileUpload from '../components/components-overview/CustomFileUpload';
import Editor from "../components/@ut-editor/@editor";
import Client from '../shared/client';
import { UtAboutUsState } from '../store/states/UtAboutUsState';
import { WarrantyState } from '../store/states/WarrantyState';

export const UtAboutUsComponent = () => {
    const [about, setAbout] = useState(UtAboutUsState);
    const [editor, setEditor] = useState(null);

    useEffect(() => {
        Client('about-us', 'GET', undefined, true).then(({ data }) => {
            const _data = data ? data : WarrantyState
            setAbout(_data);
        }).catch(e => {
            store.addNotification({
                title: "Fetch about failed",
                message: `Đã có lỗi xảy ra trong quá trình truy vấn dữ liệu`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        })
    }, []);

    const onChange = ({ target }) => {
        const { name, value } = target;
        const updateAbout = {
            ...about,
            [name]: value
        }
        setAbout(updateAbout);
    }

    const onSubmit = () => {
        const content = editor.getData();
        const updateAbout = {
            ...about,
            content
        }
        delete updateAbout.id
        Client('about-us', 'POST', updateAbout, true).then(({ data }) => {
            setAbout(data);
            store.addNotification({
                title: "Update successful",
                message: `About: ${data.title} was updated successfully.`,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            })
        }).catch(e => {
            store.addNotification({
                title: "Error!",
                message: `Failed to update contacts: ${about.title}.`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        });;
    }

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4" style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center'
            }}>
                <PageTitle title="Về chúng tôi"
                    subtitle="Chỉnh sửa thông tin"
                    className="text-sm-left mb-3" />
            </Row>
            <Tabs>
                <TabList>
                    <Tab>Editor</Tab>
                </TabList>
                <TabPanel>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12} sm={12}>
                                    <FormGroup>
                                        <label htmlFor="p-title">Title</label>
                                        <FormInput placeholder="Tiêu đề"
                                            name="title"
                                            className="mb-2" id="p-title"
                                            value={about.title}
                                            onChange={e => {
                                                onChange(e)
                                            }} />
                                        <CustomFileUpload
                                            marginText={`mb-1`}
                                            onFileChange={(fileUrl, originalName) => {
                                                const target = {
                                                    name: 'profileFile',
                                                    value: {
                                                        fileUrl,
                                                        originalName,
                                                    },
                                                };
                                                onChange({ target });
                                            }}
                                            fileName={about && about.profileFile.originalName || ''}
                                            endpoint='ut-uploader'
                                            link={about && about.profileFile.fileUrl || ''} />
                                    </FormGroup>
                                    <Editor value={about.content}
                                        onChange={(editor) => {
                                            setEditor(editor);
                                        }} />
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <Button theme={'success'}
                                onClick={() => onSubmit()}>Update</Button>
                        </CardFooter>
                    </Card>
                </TabPanel>
            </Tabs>
        </Container>
    )
}

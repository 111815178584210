import React from 'react';
import { Redirect } from 'react-router-dom';

// Layout Types
import { DefaultLayout, Login as LoginLayout } from './layouts';

// Route Views
import BlogOverview from './views/BlogOverview';
// import UserProfileLite from "./views/UserProfileLite";
// import Errors from "./views/Errors";
// import ComponentsOverview from "./views/ComponentsOverview";
// import Tables from "./views/Tables";
// import BlogPosts from "./views/BlogPosts";
import Login from './views/@Login';
import EditHomePage from './views/@EditHomePage';
import UtEditHomePage from './views/@UtEditHomePage';
import EditProductsPage from './views/@EditProductsPage';
import EditNewsPage from './views/@EditNewsPage';
import AddUpdateNewsPage from './views/@AddUpdateNewsPage';
// import AddNewPost from "./views/AddNewPost";
import Project from './views/@Project';
import Solution from './views/@Solution';
import EditProjectPage from './views/@EditProjectPage';
import EditSolutionsPage from './views/@EditSolutionsPage';
import ContactPage from './views/@ContactPage';
import WarrantyComponent from './views/@WarrantyPage';
import UserManagerPage from './views/@UserManagerPage';
import { DownloadsPage } from './views/@DownloadsPage';
import { RegisterProjectPage } from './views/@RegisterProjectPage';
import AboutComponent from './views/@AboutPage';
import TermComponent from './views/@TermPage';
import UtServicePage from './views/@UtServicePage';
import UtEditServicePage from './views/@UtEditServicePage';
import { UtNewsLettersPage } from './views/@UtNewsLettersPage';
import { UtProjectsPage } from './views/@UtProjectsPage';
import { UtEditProjectPage } from './views/@UtEditProjectPage';
import { UtEditNewsPage } from './views/@UtEditNewsPage';
import { UtAddUpdateNewsPage } from './views/@UtAddUpdateNewsPage';
import { UtEditProductsPage } from './views/@UtEditProductsPage';
import { UtAddUpdateProductsPage } from './views/@UtAddUpdateProductPage';
import { UtAboutComponent } from './views/@UtAboutPage';
import { UtAboutUsComponent } from './views/@UtAboutUsPage';

export default [
    {
        path: '/login',
        exact: true,
        layout: LoginLayout,
        component: Login,
    },
    {
        path: '/',
        exact: true,
        layout: DefaultLayout,
        component: () => <Redirect to="/edit-homepage" />,
    },
    {
        path: '/blog-overview',
        layout: DefaultLayout,
        component: BlogOverview,
    },
    {
        path: '/edit-homepage',
        layout: DefaultLayout,
        component: EditHomePage,
    },
    {
        path: '/edit-products',
        layout: DefaultLayout,
        component: EditProductsPage,
    },
    {
        path: '/projects',
        layout: DefaultLayout,
        component: Project,
    },
    {
        path: '/register-project',
        layout: DefaultLayout,
        component: RegisterProjectPage,
    },
    {
        path: '/solutions',
        layout: DefaultLayout,
        component: Solution,
    },
    {
        path: '/contact',
        layout: DefaultLayout,
        component: ContactPage,
    },
    {
        path: '/downloads',
        layout: DefaultLayout,
        component: DownloadsPage,
    },
    {
        path: '/warranty',
        layout: DefaultLayout,
        component: WarrantyComponent,
    },
    {
        path: '/term',
        layout: DefaultLayout,
        component: TermComponent,
    },
    {
        path: '/about',
        layout: DefaultLayout,
        component: AboutComponent,
    },
    {
        path: '/edit-news',
        layout: DefaultLayout,
        component: EditNewsPage,
    },
    {
        path: '/add-news-events',
        layout: DefaultLayout,
        component: AddUpdateNewsPage,
    },
    {
        path: '/edit-project',
        layout: DefaultLayout,
        component: EditProjectPage,
    },
    {
        path: '/edit-solution',
        layout: DefaultLayout,
        component: EditSolutionsPage,
    },
    {
        path: '/users-manager',
        layout: DefaultLayout,
        component: UserManagerPage,
    },
    {
        path: '/ut-home',
        layout: DefaultLayout,
        component: UtEditHomePage,
    },
    {
        path: '/ut-services',
        layout: DefaultLayout,
        component: UtServicePage,
    },
    {
        path: '/edit-ut-service',
        layout: DefaultLayout,
        component: UtEditServicePage,
    },
    {
        path: '/ut-newsletters',
        layout: DefaultLayout,
        component: UtNewsLettersPage,
    },
    {
        path: '/ut-projects',
        layout: DefaultLayout,
        component: UtProjectsPage,
    },
    {
        path: '/ut-edit-project',
        layout: DefaultLayout,
        component: UtEditProjectPage,
    },
    {
        path: '/ut-news',
        layout: DefaultLayout,
        component: UtEditNewsPage,
    },
    {
        path: '/ut-add-news-events',
        layout: DefaultLayout,
        component: UtAddUpdateNewsPage,
    },
    {
        path: '/ut-products',
        layout: DefaultLayout,
        component: UtEditProductsPage,
    },
    {
        path: '/ut-add-update-product',
        layout: DefaultLayout,
        component: UtAddUpdateProductsPage,
    },
    {
        path: '/ut-about-us',
        layout: DefaultLayout,
        component: UtAboutUsComponent,
    },
    {
        path: '/ut-contact',
        layout: DefaultLayout,
        component: UtAboutComponent,
    }
];

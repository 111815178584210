import React, { useState, useEffect, useContext } from 'react';
import Editor from '../@ut-editor/@editor';
import {
    Button,
    Card,
    CardBody, CardFooter,
    Col,
    FormGroup,
    FormInput,
    Row,
    DatePicker
} from "shards-react";
import ProjectState from "../../store/states/ProjectState";
import CustomFileUpload from "../components-overview/CustomFileUpload";
import Client from '../../shared/client';
import { store } from "react-notifications-component";
import { AuthContext } from "../../store/contexts/AuthContext";
import moment from "moment";
import UtServiceState from '../../store/states/UtServiceState';
import Select from "react-select";

const UtServiceComponent = ({ service }) => {
    const [user,] = useContext(AuthContext);
    const [serviceData, setServiceData] = useState(UtServiceState);
    const [editor, setEditor] = useState(null);
    const [isBtnCreateDisabled, setBtnCreateDisabled] = useState(true);

    useEffect(() => {
        setServiceData(service);
    }, [service])

    useEffect(() => {
    }, [serviceData])

    const onChange = ({ target }) => {
        const { name, value } = target;
        setServiceData({
            ...serviceData,
            [name]: value
        })
        if (editor === null || serviceData.title.trim() === '' || editor.getData().trim() === '') {
            setBtnCreateDisabled(true);
        } else setBtnCreateDisabled(false);
    }

    const onSubmit = () => {
        const _serviceData = { ...serviceData };
        delete _serviceData.creator;
        delete _serviceData.id;
        _serviceData.content = editor.getData();
        if (!serviceData.id) {
            Client('business-type', 'POST', _serviceData, true).then(({ data }) => {
                store.addNotification({
                    title: "Create successful",
                    message: `Project: ${data.title} was created successfully.`,
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
            }).catch(e => {
                store.addNotification({
                    title: "Error!",
                    message: `Tạo dịch vụ thất bại: ${serviceData.title}.`,
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
            })
        } else {
            Client(`business-type/${serviceData.id}`, 'PATCH', _serviceData, true).then(({ data }) => {
                store.addNotification({
                    title: "Cập nhật thành công",
                    message: `Dịch vụ: ${data.title} đã được cập nhật thành công. Bạn sẽ được điều hướng về trang DS Dịch vụ trong 3s.`,
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
                setTimeout(() => {
                    window.location.assign('/ut-services');
                }, 3000)
            })
        }
    }

    const isAllowed = () => {
        // if (!serviceData.id) return true;
        // if (user.roles.includes('ADMIN')) return true;
        // else return project.creator.id === user.id;
        return true;
    }

    const onDel = () => {
        if (window.confirm('Xác nhận xoá?')) {
            Client(`business-type/${serviceData.id}`, 'DELETE', undefined, true).then(data => {
                store.addNotification({
                    title: "Xoá thành công",
                    message: `Dịch vụ: ${serviceData.title} đã được xoá. Bạn sẽ được điều hướng về trang DS Dịch vụ trong 3s.`,
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
                setTimeout(() => {
                    window.location.assign('/ut-services');
                }, 3000)
            }).catch(e => {
                store.addNotification({
                    title: "Error!",
                    message: `Thất bại trong việc xoá dịch vụ: ${serviceData.title}. Message: ${e}`,
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
            })
        }
    }

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col md={12} sm={12}>
                        <Row>
                            <Col md={6} sm={12}>
                                <FormGroup>
                                    <label htmlFor="p-title">Tiêu đề</label>
                                    <FormInput placeholder="Dịch vụ.."
                                        name="title"
                                        className="mb-2" id="p-title"
                                        value={serviceData.title}
                                        onChange={e => {
                                            onChange(e)
                                        }} />
                                </FormGroup>
                            </Col>
                            <Col md={6} sm={12}>
                                <label htmlFor="p-image">Image</label>
                                <CustomFileUpload id="p-image" marginText="mb-2"
                                    link={serviceData.image.fileUrl}
                                    fileName={serviceData.image.originalName}
                                    endpoint='ut-uploader'
                                    title="Độ phân giải khuyến nghị 350px × 243px"
                                    onFileChange={(fileUrl, originalName) => {
                                        const target = {
                                            name: 'image',
                                            value: {
                                                fileUrl, originalName
                                            }
                                        };
                                        onChange({ target })
                                    }} />
                            </Col>
                        </Row>
                        <Editor value={serviceData.content}
                            onChange={(editor) => {
                                if (serviceData.title.trim() === '' || editor.getData().trim() === '') {
                                    setBtnCreateDisabled(true);
                                } else setBtnCreateDisabled(false);
                                setEditor(editor);
                            }} />
                    </Col>
                </Row>
            </CardBody>
            <CardFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {serviceData.id ?
                    <Button theme={'danger'} disabled={!isAllowed()}
                        onClick={() => onDel()}>Delete</Button> : <></>}
                <Button theme={'success'} disabled={!isAllowed() || isBtnCreateDisabled}
                    className="ml-5"
                    onClick={() => onSubmit()}>{serviceData.id ? 'Update' : 'Create'}</Button>
            </CardFooter>
        </Card>
    )
}

export default UtServiceComponent;

/*
 * Created by @tranphuquy19 on 07/01/2021
 * @author: tranphuquy19@gmail.com
 */
import React, { useState, useContext } from 'react';
import { Button, Card, CardBody, CardHeader, Tooltip } from 'shards-react';
import Client from '../../shared/client';
import { AuthContext } from '../../store/contexts/AuthContext';
import config from '../../shared/config';
import moment from 'moment';
import { store } from 'react-notifications-component';
import { UtProjectsContext } from '../../store/contexts/UtProjectsContext';
import {
    utProjectsReducer,
    FETCH_UT_PROJECTS,
} from '../../store/reducers/UtProjectsReducer';

export const UtProjectsTable = ({ history, ...e }) => {
    const [utProjectsData, dispatchUtProjectsData] = useContext(
        UtProjectsContext,
    );
    const [user] = useContext(AuthContext);
    const [tooltipState, setTooltipState] = useState(false);
    const [itemHovered, setItemHovered] = useState('');

    const stripHtml = html => {
        let tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    };

    const onRefresh = () => {
        Client('business', 'GET', undefined, true)
            .then(({ data }) => {
                dispatchUtProjectsData({
                    type: FETCH_UT_PROJECTS,
                    payload: [...data],
                });
                store.addNotification({
                    title: 'Refreshing projects successful',
                    message: `The projects table was updated`,
                    type: 'success',
                    insert: 'bottom',
                    container: 'bottom-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    },
                });
            })
            .catch(e => {
                store.addNotification({
                    title: 'Error!',
                    message: `Failed to refresh projects: ${e}.`,
                    type: 'danger',
                    insert: 'bottom',
                    container: 'bottom-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    },
                });
            });
    };

    const onEdit = id => {
        history.push(`/ut-edit-project?id=${id}`);
    };

    const renderEditBtn = id => {
        return (
            <Button
                onClick={() => {
                    onEdit(id);
                }}
            >
                Edit
            </Button>
        );
    };

    const viewProject = model => {
        const win = window.open(
            `${config.UT_API_URL}/du-an/${encodeURIComponent(model)}`,
            '_blank',
        );
        win.focus();
    };

    const getTableBody = () => {
        return utProjectsData.map((p, i) => {
            return (
                <tr key={i}>
                    <td>{i + 1}</td>
                    <td title={p.title.slice(0, 63)}>{p.title.slice(0, 25)}</td>
                    <td title={stripHtml(p.content).slice(0, 63)}>{stripHtml(p.content).slice(0, 50) + '...'}</td>
                    <td>{p.businessType.title}</td>
                    <td
                        id={`tt-${p.id}`}
                        onMouseEnter={() => setItemHovered(p.id)}
                        onMouseLeave={() => setItemHovered('')}
                    >
                        {moment(p.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
                    </td>
                    <td>
                        {renderEditBtn(p.id)}

                        <Button
                            theme={'success'}
                            className="ml-2"
                            onClick={() => {
                                viewProject(p.id);
                            }}
                        >
                            View
                        </Button>
                    </td>
                    <Tooltip
                        target={`#tt-${p.id}`}
                        open={itemHovered === p.id && tooltipState}
                        toggle={() => setTooltipState(!tooltipState)}
                    >
                        <b>
                            Created at:{' '}
                            {moment(p.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                        </b>
                    </Tooltip>
                </tr>
            );
        });
    };

    return (
        <Card small className="mb-4">
            <CardHeader
                className="border-bottom"
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <h6 className="m-0">Projects</h6>
                <Button theme={'warning'} onClick={() => onRefresh()}>
                    Refresh
                </Button>
            </CardHeader>
            <CardBody className="p-0 pb-3">
                <table
                    className="table mb-0"
                    style={{ maxHeight: '500px', overflowY: 'scroll' }}
                >
                    <thead className="bg-light">
                        <tr>
                            <th scope="col" className="border-0">
                                #
                            </th>
                            <th scope="col" className="border-0">
                                Title
                            </th>
                            <th scope="col" className="border-0">
                                Content
                            </th>
                            <th scope="col" className="border-0">
                                Lĩnh vực
                            </th>
                            <th scope="col" className="border-0">
                                Updated At
                            </th>
                            <th scope="col" className="border-0">
                                &nbsp;
                            </th>
                        </tr>
                    </thead>
                    <tbody>{getTableBody()}</tbody>
                </table>
            </CardBody>
        </Card>
    );
};

/*
 * Created by @tranphuquy19 on 20/02/2021
 * @author: tranphuquy19@gmail.com
 */
import React, { useContext, useState } from 'react';

import {
    Card,
    CardHeader,
    Col,
    Form,
    FormInput,
    ListGroup,
    ListGroupItem,
    Row,
    FormCheckbox,
    Collapse,
} from 'shards-react';
import { UtHomeContext } from '../../store/contexts/UtHomeContext';
import {
    UT_ABOUT_US,
    UT_EDIT_OUR_STORY,
    UT_TOGGLE_ABOUT_US,
} from '../../store/reducers/UtHomeReducer';
import CustomFileUpload from '../components-overview/CustomFileUpload';

export const UtAboutUs = () => {
    const [utHomeData, dispatchUtHomeData] = useContext(UtHomeContext);
    const [open, setOpen] = useState(false);

    const onStoryTextChange = e => {
        const {
            target: { name, value },
        } = e;
        dispatchUtHomeData({
            type: UT_ABOUT_US,
            payload: {
                ...utHomeData.aboutUs,
                [name]: value,
            },
        });
    };

    const onFileChange = (fileUrl, originalName) => {
        dispatchUtHomeData({
            type: UT_EDIT_OUR_STORY,
            payload: {
                ...utHomeData.aboutUs,
                storyImage: { fileUrl, originalName },
            },
        });
    };

    return (
        <Card className="mb-4">
            <CardHeader className="border-bottom d-flex flex-row">
                <FormCheckbox
                    toggle
                    checked={utHomeData.isShowAboutUs}
                    onChange={() =>
                        dispatchUtHomeData({
                            type: UT_TOGGLE_ABOUT_US,
                        })
                    }
                ></FormCheckbox>
                <h6
                    className="m-0"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setOpen(!open)}
                >
                    About us
                    <i className="material-icons">
                        {open ? 'expand_less' : 'expand_more'}
                    </i>
                </h6>
            </CardHeader>
            <ListGroup flush>
                <Collapse open={open}>
                    <ListGroupItem className="p-3">
                        <Row>
                            <Col>
                                <Form>
                                    <Row form>
                                        <Col md="12" className="form-group">
                                            <label htmlFor="aboutUsTitle">
                                                Tiêu đề text
                                            </label>
                                            <FormInput
                                                name="title"
                                                id="aboutUsTitle"
                                                type="text"
                                                placeholder="WE DELIVER LANDMARK PROJECTS"
                                                value={utHomeData.aboutUs.title}
                                                onChange={e =>
                                                    onStoryTextChange(e)
                                                }
                                                required
                                                disabled={
                                                    !utHomeData.isShowAboutUs
                                                }
                                            />
                                        </Col>
                                        <Col md="12" className="form-group">
                                            <label htmlFor="aboutUsDescription">
                                                Mô tả
                                            </label>
                                            <FormInput
                                                name="description"
                                                id="aboutUsDescription"
                                                type="text"
                                                placeholder="Type your description"
                                                value={
                                                    utHomeData.aboutUs
                                                        .description
                                                }
                                                onChange={e =>
                                                    onStoryTextChange(e)
                                                }
                                                required
                                                disabled={
                                                    !utHomeData.isShowAboutUs
                                                }
                                            />
                                            {/* <CustomFileUpload
                                            marginText={`mb-1`}
                                            onFileChange={(fileUrl, originalName) => onFileChange(fileUrl, originalName)}
                                            fileName={utHomeData.ourStory.storyImage.originalName}
                                            link={utHomeData.ourStory.storyImage.fileUrl}/> */}
                                        </Col>
                                        <Col md="12" className="form-group" hidden>
                                            <label htmlFor="aboutUsItems">
                                                4 Tiêu chí{' '}
                                                <b>
                                                    &#91;Icon&nbsp;&#124;&nbsp;Mô
                                                    tả&#93;
                                                </b>
                                                &nbsp;
                                                <a
                                                    href="https://fontawesome.com/v5/search?o=r&m=free"
                                                    target="blank"
                                                >
                                                    <i className="fa fa-question-circle ml-1"></i>
                                                    Click để xem danh sách các
                                                    icons
                                                </a>
                                            </label>
                                            <Row id="aboutUsItems">
                                                <Col md="2" sm="12">
                                                    <FormInput
                                                        name="icon1"
                                                        id="icon1"
                                                        type="text"
                                                        placeholder="trophy"
                                                        value={
                                                            utHomeData.aboutUs
                                                                .icon1
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                        disabled={
                                                            !utHomeData.isShowAboutUs
                                                        }
                                                    />
                                                </Col>
                                                <Col md="10" sm="12">
                                                    <FormInput
                                                        name="icon1Text"
                                                        id="icon1Text"
                                                        type="text"
                                                        placeholder=""
                                                        value={
                                                            utHomeData.aboutUs
                                                                .icon1Text
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                        disabled={
                                                            !utHomeData.isShowAboutUs
                                                        }
                                                    />
                                                </Col>
                                                <Col md="2" sm="12">
                                                    <FormInput
                                                        name="icon2"
                                                        id="icon2"
                                                        type="text"
                                                        placeholder="sliders-h"
                                                        value={
                                                            utHomeData.aboutUs
                                                                .icon2
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                        disabled={
                                                            !utHomeData.isShowAboutUs
                                                        }
                                                    />
                                                </Col>
                                                <Col md="10" sm="12">
                                                    <FormInput
                                                        name="icon2Text"
                                                        id="icon2Text"
                                                        type="text"
                                                        placeholder=""
                                                        value={
                                                            utHomeData.aboutUs
                                                                .icon2Text
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                        disabled={
                                                            !utHomeData.isShowAboutUs
                                                        }
                                                    />
                                                </Col>
                                                <Col md="2" sm="12">
                                                    <FormInput
                                                        name="icon3"
                                                        id="icon3"
                                                        type="text"
                                                        placeholder="thumbs-up"
                                                        value={
                                                            utHomeData.aboutUs
                                                                .icon3
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                        disabled={
                                                            !utHomeData.isShowAboutUs
                                                        }
                                                    />
                                                </Col>
                                                <Col md="10" sm="12">
                                                    <FormInput
                                                        name="icon3Text"
                                                        id="icon3Text"
                                                        type="text"
                                                        placeholder=""
                                                        value={
                                                            utHomeData.aboutUs
                                                                .icon3Text
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                        disabled={
                                                            !utHomeData.isShowAboutUs
                                                        }
                                                    />
                                                </Col>
                                                <Col md="2" sm="12">
                                                    <FormInput
                                                        name="icon4"
                                                        id="icon4"
                                                        type="text"
                                                        placeholder="users"
                                                        value={
                                                            utHomeData.aboutUs
                                                                .icon4
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                        disabled={
                                                            !utHomeData.isShowAboutUs
                                                        }
                                                    />
                                                </Col>
                                                <Col md="10" sm="12">
                                                    <FormInput
                                                        name="icon4Text"
                                                        id="icon4Text"
                                                        type="text"
                                                        placeholder=""
                                                        value={
                                                            utHomeData.aboutUs
                                                                .icon4Text
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                        disabled={
                                                            !utHomeData.isShowAboutUs
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md="8" className="form-group">
                                            <label htmlFor='aboutUsLink'>
                                                Link
                                            </label>
                                            <FormInput
                                                name='link'
                                                id='aboutUsLink'
                                                type='text'
                                                placeholder='Type your link'
                                                value={utHomeData.aboutUs.link}
                                                onChange={e => onStoryTextChange(e)}
                                                required
                                                disabled={!utHomeData.isShowAboutUs}
                                            />
                                        </Col>
                                        <Col md="4" className="form-group">
                                            <label htmlFor='aboutUsLinkText'>
                                                Link text
                                            </label>
                                            <FormInput
                                                name='linkText'
                                                id='aboutUsLinkText'
                                                type='text'
                                                placeholder='Type your link text'
                                                value={utHomeData.aboutUs.linkText}
                                                onChange={e => onStoryTextChange(e)}
                                                required
                                                disabled={!utHomeData.isShowAboutUs}
                                            />
                                        </Col>
                                        <Col md="12" className="form-group" hidden>
                                            <label htmlFor="aboutUsDescription">
                                                Mô tả ngắn (footer)
                                            </label>
                                            <FormInput
                                                name="shortDescription"
                                                id="shortDescription"
                                                type="text"
                                                placeholder="Type your description"
                                                value={
                                                    utHomeData.aboutUs
                                                        .shortDescription
                                                }
                                                onChange={e =>
                                                    onStoryTextChange(e)
                                                }
                                                required
                                                disabled={
                                                    !utHomeData.isShowAboutUs
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </ListGroupItem>
                </Collapse>
            </ListGroup>
        </Card>
    );
};
